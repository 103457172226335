/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import * as style from "./ArticleList.module.css"
import { IoDocumentTextOutline } from "react-icons/io5"


const ArticleList = ({ articles }) => {

  const renderLogo = (logoUrl) => {
    if (logoUrl) {
      return <img src={logoUrl} className={style.articleListLogo}></img> 
    } else {
      return <IoDocumentTextOutline className={style.articleListLogo}/>
    }
  }
  return (
    <ul className={style.articleList}>
      {
        articles.map(article => {
          return (
            <li className={style.articleListItem}>
              
              <span className={style.articleListTitle}>
                { 
                  renderLogo(article.logo)
                }
                <a sx={{ color: 'primary' }}
                  className={style.articleListLink} href={article.link} target="_blank" rel="noreferrer">
                    {article.title}
                </a>
              </span>

              <span className={style.articleListPublisher}> Published by { article.publisher } </span>
              <span
                className={style.articleListDescription}
                sx={{ color: 'secondary' }}>
                  {article.description}
              </span>
            </li>
          )
        })
      }
    </ul>
  )
}

export default ArticleList