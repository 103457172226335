import { jsx, css, Themed, Box, Link } from "theme-ui"
import * as React from "react"
import SubTopicList from "../components/SubTopicList"
import dayjs from 'dayjs'

import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5"

dayjs.extend(require('dayjs/plugin/isToday'))
dayjs.extend(require('dayjs/plugin/isYesterday'))
dayjs.extend(require('dayjs/plugin/utc'))

const WorkspaceDaily = ({ data }) => {
  const [currentDate, setCurrentDate] = React.useState(0)
  
  const renderDateSwitch = () => {
    const switches = []
    if (currentDate > 0) {
      switches.push(<IoArrowBackSharp onClick={() => { setCurrentDate(currentDate - 1) }}/>)
    }

    if (currentDate < (data.length - 1)) {
      switches.push(<IoArrowForwardSharp onClick={() => { setCurrentDate(currentDate + 1) }}/>)
    }
    return switches
  }

  const renderDateHeader = () => {
    if (!data[currentDate]) {
      return <h2> Busy finding trends... </h2>
    }

    const date = dayjs(data[currentDate].date).utc()
    let dateText
    if (date.isToday()) {
      dateText = "Today's Read"
    } else if (date.isYesterday()) {
      dateText = "Yesterday"
    } else {
      dateText = date.utc().format("MMM DD, ddd")
    }
    return <h2> {dateText} { renderDateSwitch() } </h2>
    
  }

  const renderArticles = () => {
    if (!data[currentDate]) {
      return ""
    }

    const dataset = data[currentDate]
    return <SubTopicList topics={dataset.topics} />
  }

  return (
    <Box>
      { renderDateHeader() }
      { renderArticles() }
    </Box>
  )
}

export default WorkspaceDaily