/** @jsx jsx */
import { jsx, css, Themed, Box, Link } from "theme-ui"
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import _ from 'lodash'
import ArticleList from "./ArticleList"

import * as style from './SubTopicList.module.css'

const SubTopicList = ({ topics }) => {
  const [currentFilter, setCurrentFilter] = React.useState('all')
  
  const handleTopicClick = (topic) => {
    setCurrentFilter(topic)
  }

  const renderArticles = () => {
    if (currentFilter === 'all') {
      const articles = _.uniqBy(_.flatten(_.map(topics, 'articles')), 'title')

      return <ArticleList articles={articles} />
    } else {
      const articles = _.uniqBy(_.find(topics, { tname: currentFilter }).articles, 'title')
      return <ArticleList articles={articles} />
    }
  }

  return (
    <div>
      <div className={style.subTopicListFilters}>
        <Link 
          className={ `${currentFilter === 'all' ? style.subTopicListFilterBtnSelected : ''} ${style.subTopicListFilterBtn}` }
          sx={currentFilter === 'all' ? { borderColor: 'primary', bg: 'primary', color: 'background' } : { borderColor: 'primary' }}
          onClick={ () => handleTopicClick('all')}> all </Link>
        {
          topics.map(topic => <Link 
            className={ `${currentFilter === topic.tname ? style.subTopicListFilterBtnSelected : ''} ${style.subTopicListFilterBtn}` }
            sx={currentFilter === topic.tname ? { borderColor: 'primary', bg: 'primary', color: 'background' } : { borderColor: 'primary' }}
            onClick={() => handleTopicClick(`${topic.tname}`)}> {topic.tname} </Link>)
        }
      </div>
      
      {
        renderArticles()
      }
    </div>
  )
}

export default SubTopicList