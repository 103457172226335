import React from "react"
import { graphql } from "gatsby"
import { Link } from "theme-ui"
import Layout from "../components/Layout"
import WorkspaceDaily from "../components/WorkspaceDaily"

export default function Workspace({ data }) {
  const workspace = data.workspace
  
  return (
    <Layout >
      <div>
        <h1>{workspace.name}</h1>
        <WorkspaceDaily data={ workspace.range }/>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($id: String!) {
    workspace(id: { eq: $id }) {
      name
      id
      range {
        date
        topics {
          tname
          wname
          model_id
          topic_id
          start_date
          end_date
          articles {
            title
            image
            description
            link
            date
            publisher
            logo
          }
        }
      }
    }
  }
`